// Action Types
export const FETCH_CHALLENGES_REQUEST = 'FETCH_CHALLENGES_REQUEST';
export const FETCH_CHALLENGES_SUCCESS = 'FETCH_CHALLENGES_SUCCESS';
export const FETCH_CHALLENGES_FAILURE = 'FETCH_CHALLENGES_FAILURE';
export const COMPLETE_CHALLENGE = 'COMPLETE_CHALLENGE';

// Action Creators
export const fetchChallengesRequest = () => ({
  type: FETCH_CHALLENGES_REQUEST,
});

export const fetchChallengesSuccess = (challenges) => ({
  type: FETCH_CHALLENGES_SUCCESS,
  payload: challenges,
});

export const fetchChallengesFailure = (error) => ({
  type: FETCH_CHALLENGES_FAILURE,
  payload: error,
});

export const completeChallenge = (challengeId) => ({
  type: COMPLETE_CHALLENGE,
  payload: challengeId,
});

// Thunk for asynchronous actions
export const fetchChallenges = () => {
  return async (dispatch) => {
    dispatch(fetchChallengesRequest());
    try {
      console.log('Fetching challenges index...');
      const indexResponse = await fetch('/challenges/index.json');
      if (!indexResponse.ok) {
        throw new Error(`Failed to fetch challenges index: ${indexResponse.statusText}`);
      }
      const challengeList = await indexResponse.json();
      console.log('Challenges index:', challengeList);
      
      console.log('Fetching individual challenges...');
      const fetchedChallenges = await Promise.all(
        challengeList.map(async (challengeId) => {
          console.log(`Fetching challenge: ${challengeId}`);
          const challengeResponse = await fetch(`/challenges/${challengeId}.json`);
          if (!challengeResponse.ok) {
            throw new Error(`Failed to fetch challenge ${challengeId}: ${challengeResponse.statusText}`);
          }
          const challengeText = await challengeResponse.text();
          try {
            return JSON.parse(challengeText);
          } catch (parseError) {
            console.error(`Error parsing challenge ${challengeId}:`, challengeText);
            throw new Error(`Failed to parse challenge ${challengeId}: ${parseError.message}`);
          }
        })
      );

      console.log('Fetched challenges:', fetchedChallenges);
      dispatch(fetchChallengesSuccess(fetchedChallenges));
    } catch (error) {
      console.error('Error fetching challenges:', error);
      dispatch(fetchChallengesFailure(error.message));
    }
  };
};