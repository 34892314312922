import { useSelector } from 'react-redux';
import { 
  selectChallenges, 
  selectChallengesLoading, 
  selectChallengesError,
  selectChallengeById
} from '../redux/selectors';

const useChallengeManager = () => {
  const challenges = useSelector(selectChallenges);
  const loading = useSelector(selectChallengesLoading);
  const error = useSelector(selectChallengesError);

  const getChallengeById = (id) => {
    return challenges.find((challenge) => challenge.id === id);
  };

  return { challenges, loading, error, getChallengeById };
};

export default useChallengeManager;