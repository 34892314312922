import React from 'react';
import ChallengeGrid from '../ChallengeGrid/ChallengeGrid';
import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles.home}>
      <section className={styles.intro}>
        <h1>Sharpen Your Leadership Edge</h1>
        <p>
          Our interactive scenarios are designed to sharpen your leadership skills through practical, real-world situations. Each challenge focuses on a key aspect of effective leadership, drawn from cutting-edge research and best practices.
        </p>
        <p>
          We encourage you to read the linked articles for each challenge to deepen your understanding of the underlying concepts. By engaging with both the challenges and the source material, you'll develop a well-rounded approach to leadership.
        </p>
        <p>
          Choose a challenge below to begin your leadership journey.
        </p>
      </section>
      <ChallengeGrid />
    </div>
  );
};

export default Home;
