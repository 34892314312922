import { COMPLETE_CHALLENGE } from './actions';

const initialState = {
  completedChallenges: {},
};

const userProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_CHALLENGE:
      return {
        ...state,
        completedChallenges: {
          ...state.completedChallenges,
          [action.payload]: true
        }
      };
    default:
      return state;
  }
};

export default userProgressReducer;