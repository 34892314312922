import React from 'react';
import { Link } from 'react-router-dom';
import useChallengeManager from '../../hooks/useChallengeManager';
import ChallengeCard from '../ChallengeCard/ChallengeCard';
import styles from './ChallengeGrid.module.css';

const ChallengeGrid = () => {
  const { challenges, loading, error } = useChallengeManager();

  if (loading) {
    return <div className={styles.loading}>Loading challenges...</div>;
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }

  return (
    <div className={styles.gridContainer}>
      {challenges.map((challenge) => (
        <Link to={`/challenge/${challenge.id}`} key={challenge.id} className={styles.cardLink}>
          <ChallengeCard challenge={challenge} />
        </Link>
      ))}
    </div>
  );
};

export default ChallengeGrid;