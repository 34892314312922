// Challenges selectors
export const selectChallenges = state => state.challenges.challenges;
export const selectChallengesLoading = state => state.challenges.loading;
export const selectChallengesError = state => state.challenges.error;

export const selectChallengeById = (state, challengeId) => 
  state.challenges.challenges.find(challenge => challenge.id === challengeId);

// User progress selectors
export const selectCompletedChallenges = state => state.userProgress.completedChallenges;

export const selectIsChallengeCompleted = (state, challengeId) => 
  !!state.userProgress.completedChallenges[challengeId];