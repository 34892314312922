import {
    FETCH_CHALLENGES_REQUEST,
    FETCH_CHALLENGES_SUCCESS,
    FETCH_CHALLENGES_FAILURE
  } from './actions';
  
  const initialState = {
    challenges: [],
    loading: false,
    error: null,
  };
  
  const challengesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CHALLENGES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_CHALLENGES_SUCCESS:
        return {
          ...state,
          loading: false,
          challenges: action.payload,
          error: null
        };
      case FETCH_CHALLENGES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default challengesReducer;