import React from 'react';
import PropTypes from 'prop-types';
import styles from './OptionList.module.css';

const OptionList = ({ options, selectedOption, onOptionSelect }) => {
  return (
    <ul className={styles.optionList}>
      {options.map((option) => (
        <li key={option.id}>
          <button
            className={`${styles.optionButton} ${selectedOption === option.id ? styles.selected : ''}`}
            onClick={() => onOptionSelect(option.id)}
          >
            {option.text}
          </button>
        </li>
      ))}
    </ul>
  );
};

OptionList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
};

export default OptionList;