import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logo}>
        LeadQuest
      </Link>
      <nav>
        <Link to="/" className={styles.navLink}>Home</Link>
        {/* Add more navigation links as needed */}
      </nav>
    </header>
  );
};

export default Header;