import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsChallengeCompleted } from '../../redux/selectors';
import styles from './ChallengeCard.module.css';

const ChallengeCard = ({ challenge }) => {
  const { id, title, description } = challenge;
  const isCompleted = useSelector(state => selectIsChallengeCompleted(state, id));

  return (
    <div className={`${styles.card} ${isCompleted ? styles.completed : ''}`}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      {isCompleted && (
        <div className={styles.completedBadge}>
          Completed
        </div>
      )}
    </div>
  );
};

ChallengeCard.propTypes = {
  challenge: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default ChallengeCard;