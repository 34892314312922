import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchChallenges } from './redux/actions';
import ChallengeModal from './components/ChallengeModal/ChallengeModal';
import AdditionalResources from './components/AdditionalResources/AdditionalResources';
import Header from './components/Header/Header';
import NotFound from './components/NotFound/NotFound';
import styles from './App.module.css';
import Home from './components/Home/Home';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchChallenges());
  }, [dispatch]);

  return (
    <Router>
      <div className={styles.app}>
        <Header />
        <main className={styles.main}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/challenge/:id" element={<ChallengeModal />} />
            <Route path="/challenge/:id/resources" element={<AdditionalResources />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
