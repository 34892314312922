import React from 'react';
import { useParams, Link } from 'react-router-dom';
import useChallengeManager from '../../hooks/useChallengeManager';
import styles from './AdditionalResources.module.css';

const AdditionalResources = () => {
  const { id } = useParams();
  const { getChallengeById } = useChallengeManager();
  const challenge = getChallengeById(id);

  if (!challenge) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Additional Resources for {challenge.title}</h2>
      <p className={styles.intro}>To further develop your leadership skills related to this challenge, we recommend exploring these valuable resources:</p>
      <ul className={styles.resourceList}>
        {challenge.additionalResources.map((resource, index) => (
          <li key={index} className={styles.resourceItem}>
            <h3 className={styles.resourceTitle}>{resource.title}</h3>
            <p className={styles.resourceDescription}>{resource.description}</p>
            {resource.link && (
              <a href={resource.link} target="_blank" rel="noopener noreferrer" className={styles.resourceLink}>
                Learn More
              </a>
            )}
          </li>
        ))}
      </ul>
      <Link to="/" className={styles.homeLink}>Return to Challenges</Link>
    </div>
  );
};

export default AdditionalResources;
