import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import challengesReducer from './challengesSlice';
import userProgressReducer from './userProgressSlice';
import { loadState, saveState } from '../utils/localStorage';

const rootReducer = combineReducers({
  challenges: challengesReducer,
  userProgress: userProgressReducer,
});

const persistedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

store.subscribe(throttle(() => {
  saveState({
    userProgress: store.getState().userProgress
  });
}, 1000));

export default store;