import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import icons
import useChallengeManager from '../../hooks/useChallengeManager';
import Question from '../Question/Question';
import { shuffleArray } from '../../utils/shuffleArray';
import { completeChallenge } from '../../redux/actions';
import { selectIsChallengeCompleted } from '../../redux/selectors';
import styles from './ChallengeModal.module.css';

const ChallengeModal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getChallengeById } = useChallengeManager();
  const challenge = getChallengeById(id);
  console.log('Challenge data:', challenge);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [shuffledOptions, setShuffledOptions] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    if (challenge) {
      const shuffled = challenge.questions.reduce((acc, question) => {
        acc[question.id] = shuffleArray([...question.options]);
        return acc;
      }, {});
      setShuffledOptions(shuffled);
    }
  }, [challenge]);

  const handleAnswerSelection = (questionId, answerId) => {
    setUserAnswers(prev => ({ ...prev, [questionId]: answerId }));
    setShowFeedback(true);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < challenge.questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      setShowFeedback(false);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleFinish = () => {
    dispatch(completeChallenge(challenge.id));
    navigate(`/challenge/${challenge.id}/resources`);
  };

  const currentQuestion = useMemo(() => {
    if (!challenge) return null;
    const question = challenge.questions[currentQuestionIndex];
    return {
      ...question,
      options: shuffledOptions[question.id] || question.options
    };
  }, [challenge, currentQuestionIndex, shuffledOptions]);

  const renderFeedback = () => {
    if (!showFeedback || !currentQuestion) return null;

    const selectedAnswer = userAnswers[currentQuestion.id];
    const selectedOption = currentQuestion.options.find(option => option.id === selectedAnswer);
    const isCorrect = selectedAnswer === currentQuestion.correctAnswer;
    const correctOption = currentQuestion.options.find(option => option.id === currentQuestion.correctAnswer);

    return (
      <div className={styles.feedback}>
        <h4>
          {isCorrect ? (
            <><FaCheckCircle color="green" /> Correct!</>
          ) : (
            <><FaTimesCircle color="red" /> Incorrect</>
          )}
        </h4>
        <div className={`${styles.answerFeedback} ${isCorrect ? styles.correctAnswer : styles.incorrectAnswer}`}>
          <strong>Your answer:</strong> {selectedOption.text}
          <p>{selectedOption.feedback}</p>
        </div>
        {!isCorrect && (
          <div className={`${styles.answerFeedback} ${styles.correctAnswer}`}>
            <strong>Correct answer:</strong> {correctOption.text}
            <p>{correctOption.feedback}</p>
          </div>
        )}
      </div>
    );
  };

  const renderScenarioIntroduction = () => {
    if (!challenge.scenarioIntroduction || challenge.scenarioIntroduction.trim() === '') return null;
    
    return (
      <div className={styles.scenarioIntroduction}>
        <h3>Scenario Introduction</h3>
        {challenge.scenarioIntroduction.split('\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    );
  };

  const renderProgressBar = () => (
    <div className={styles.progressBar}>
      <div 
        className={styles.progressFill} 
        style={{width: `${((currentQuestionIndex + 1) / challenge.questions.length) * 100}%`}}
      ></div>
    </div>
  );

  if (!challenge) {
    return <div className={styles.loading}>Loading challenge...</div>;
  }

  return (
    <div className={styles.modal}>
      <h2 className={styles.title}>{challenge.title}</h2>
      <p className={styles.description}>{challenge.description}</p>
      {challenge.articleLink && (
        <a href={challenge.articleLink} target="_blank" rel="noopener noreferrer" className={styles.articleLink}>
          Read the article
        </a>
      )}
      {renderScenarioIntroduction()}
      {currentQuestion && (
        <>
          <Question
            question={currentQuestion}
            selectedAnswer={userAnswers[currentQuestion.id]}
            onAnswerSelect={handleAnswerSelection}
          />
          {renderFeedback()}
        </>
      )}
      <div className={styles.navigation}>
        <button 
          onClick={handlePreviousQuestion} 
          disabled={currentQuestionIndex === 0}
          className={styles.navButton}
        >
          Previous
        </button>
        {currentQuestionIndex === challenge.questions.length - 1 ? (
          <button onClick={handleFinish} className={styles.submitButton}>Finish</button>
        ) : (
          <button 
            onClick={handleNextQuestion} 
            disabled={!showFeedback}
            className={styles.navButton}
          >
            Next
          </button>
        )}
      </div>
      {renderProgressBar()}
      <div className={styles.progress}>
        Question {currentQuestionIndex + 1} of {challenge.questions.length}
      </div>
    </div>
  );
};

export default ChallengeModal;
