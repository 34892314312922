// Shuffles array in place using Fisher-Yates algorithm
export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  
  // Validates a challenge object against our schema
  export const validateChallenge = (challenge) => {
    const requiredFields = ['id', 'title', 'description', 'questions'];
    const missingFields = requiredFields.filter(field => !(field in challenge));
    
    if (missingFields.length > 0) {
      return { isValid: false, errors: `Missing required fields: ${missingFields.join(', ')}` };
    }
    
    if (!Array.isArray(challenge.questions) || challenge.questions.length === 0) {
      return { isValid: false, errors: 'Questions must be a non-empty array' };
    }
    
    return { isValid: true };
  };
  
  // Formats a date to a string (e.g., "May 15, 2023")
  export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };
