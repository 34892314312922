import React from 'react';
import PropTypes from 'prop-types';
import OptionList from '../OptionList/OptionList';
import styles from './Question.module.css';

const Question = ({ question, selectedAnswer, onAnswerSelect }) => {
  return (
    <div className={styles.questionContainer}>
      <h3 className={styles.questionText}>{question.text}</h3>
      <OptionList
        options={question.options}
        selectedOption={selectedAnswer}
        onOptionSelect={(optionId) => onAnswerSelect(question.id, optionId)}
      />
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  selectedAnswer: PropTypes.string,
  onAnswerSelect: PropTypes.func.isRequired,
};

export default Question;